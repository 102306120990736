// gatsby-browser.ts

// gatsby-browser.ts
import "bootstrap/dist/css/bootstrap.min.css";   // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JS

import "./src/styles/enquiryForm.css";


// Add any other global JS or client-side logic here if needed
// For example, if you want to add smooth scrolling or other JS libraries:
export const onClientEntry = () => {
  console.log("Gatsby has started loading.");
};
